<template>
  <div class="item">
    <div class="container">
      <div class="title-box">
        <div class="title">
          <p>融盟M</p>
          <span v-html="intro"></span>
        </div>
        <div class="gd"><a href="http://2022.jiemugo.com/"
             target="_blank"
             v-html="gd"></a>
        </div>
      </div>
      <div class="specialization-program">
        <div class="specialization-program-tab_con">
          <div class="specialization-program-video clearfix">
            <div class="specialization-program-bigvideo">
              <div class="specialization-program-bigvideo-img">
                <a :href="list.tzbqg.url"
                   target="_blank">
                  <img :src="list.tzbqg.cover">
                </a>
              </div>
            </div>
            <ul>
              <li v-for="l in list.tzbqgs"
                  :key="l.id">
                <a :href="l.url"
                   target="_blank">
                  <img :src="l.cover"
                       :alt="l.title"
                       :title="l.title">
                </a>
                <div class="video-tag"
                     v-show="l.is_4k!=0"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="xian"></div>
    </div>
  </div>
</template>

<script>
import { reqJmg } from "@/api"

export default {
  name: 'ItemView',
  data () {
    return {
      list: {
        "tzbqg": {
        }, //单条数据
        "tzbqgs": [
        ] //集合
      },
      gd: '查看更多>>',
      intro:'全国广电融媒体联盟 融媒体协作发展平台'
    }
  },
  props: ['windowWidth'],
  mounted () {
    reqJmg({ type: 3 }).then((result) => {
      if (result.success) {
        this.list = result.data
      } else {
        alert('页面加载失败1')
      }
    }).catch((err) => {
      alert('页面加载失败2')
      console.log(err);
    });
  },
  watch: {
    windowWidth: {
      handler (a, b) {
        if (a < 768) {
          this.gd = '更多>>'
          this.intro='全国广电协作发展平台'
        } else {
          this.gd = '查看更多>>'
          this.intro='全国广电融媒体联盟 融媒体协作发展平台'
        }
      },
      immediate: true
    }
  },


}
</script>

<style scoped>
.gd a {
  color: #000;
  font-size: 14px;
}
.xian {
  width: 80%;
  height: 1px;
  background-color: #888;
  margin: 0 auto;
}
.container {
  width: 1250px;
}
.title-box {
  margin-top: 80px;
  height: 35px;
}
.title {
  float: left;
}
.title p {
  display: inline-block;
  font-size: 26px;
  color: #000;
  font-weight: 600;
}
.title span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  color: #000;
  text-align: center;
}
.gd {
  float: right;
  margin-top: 15px;
  margin-right: 18px;
}
/* 专业化频道节目 开始 */

.specialization-program {
  padding-top: 50px;
  padding-bottom: 50px;
}

.specialization-program-tab_con {
  width: 100%;
}

.specialization-program-bigvideo {
  float: left;
  width: 48%;
  overflow: hidden;
  border-radius: 8px;
}

.specialization-program-bigvideo img {
  width: 100%;
  height: 344px;
  transition: all 0.6s;
}

.specialization-program-bigvideo-img {
  width: 100%;
  height: 344px;
}

.specialization-program-bigvideo-img:hover {
  transition: all 0.6s;
}

.specialization-program-bigvideo-img:hover img {
  transform: scale(1.05);
  -webkit-filter: contrast(30);
  filter: contrast(130%);
}
.specialization-program-tab_con ul {
  width: 50%;
  float: left;
}

.specialization-program-tab_con ul li {
  position: relative;
  float: left;
  width: 48%;
  height: 164px;
  margin-left: 2%;
  margin-bottom: 18px;
  overflow: hidden;
  border-radius: 8px;
}

.specialization-program-tab_con ul li:hover img {
  transform: scale(1.05);
  -webkit-filter: contrast(30);
  filter: contrast(130%);
  transition: all 0.6s;
}

.specialization-program-tab_con ul li img {
  width: 100%;
  height: 164px;
  transition: all 0.6s;
}
.video-tag {
  position: absolute;
  width: 36px;
  height: 24px;
  top: 12px;
  z-index: 1;
  left: 12px;
  right: 12px;
  background: url(@/assets/image/下载.png) no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1250px) {
  .container {
    width: 90%;
  }
}
@media screen and (max-width: 992px) {
  .container {
    width: 90%;
  }
  .specialization-program-bigvideo-img {
    height: 240px;
  }
  .specialization-program-bigvideo img {
    height: 100%;
  }
  .specialization-program-tab_con ul li {
    width: 48%;
    height: 112px;
    margin-bottom: 17px;
  }
  .specialization-program-tab_con ul li img {
    height: 100%;
  }

  .specialization-program-tab_con ul li {
    margin-left: 2%;
  }
  @media screen and (max-width: 768px) {
    .container[data-v-a4368304] {
      width: 95%;
    }
    .specialization-program {
      padding: 20px 0;
    }
    .xian {
      display: none;
    }
    .title-box {
      position: relative;
      margin-top: 18px;
      height: 53px;
    }
    .title p {
      display: block;
      font-size: 20px;
    }
    .title span {
      margin-left: 0;
      width: 300px;
      text-align: left;
    }
    .gd {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
    }
    .specialization-program-bigvideo {
      width: 100%;
    }
    .specialization-program-bigvideo-img {
      height: auto;
    }
    .specialization-program-bigvideo img {
      height: auto;
    }
    .specialization-program-tab_con ul {
      width: 100%;
      margin-top: 20px;
    }
    .specialization-program-tab_con ul li {
      margin-left: 0;
      margin-right: 4%;
    }
    .specialization-program-tab_con ul li:nth-child(2n) {
      margin-right: 0;
    }
    .specialization-program-tab_con ul li {
      height: auto;
    }
  }
}
</style>