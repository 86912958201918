<template>
  <div>
    <bannerView></bannerView>
    <Sitadvantage></Sitadvantage>
    <TjtItem :windowWidth="windowWidth"></TjtItem>
    <JmgItem :windowWidth="windowWidth"></JmgItem>
    <RmmItem :windowWidth="windowWidth"></RmmItem>
    <VlItem :windowWidth="windowWidth"></VlItem>
    <copyright></copyright>
  </div>
</template>

<script>
import bannerView from '@/views/home/bannerView'
import Sitadvantage from "@/views/home/SiteAdvantage"
import JmgItem from '@/views/home/JmgItem'
import TjtItem from '@/views/home/TjtItem'
import RmmItem from '@/views/home/RmmItem'
import VlItem from '@/views/home/VlItem'
import copyright from '@/views/home/copyright'


export default {
  name: 'home',
  data () {
    return {
      windowWidth: document.body.clientWidth, //实时屏幕宽度
    };
  },
  components: { bannerView, Sitadvantage, JmgItem, copyright, TjtItem, RmmItem, VlItem },
  mounted () {
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.body.clientWidth;
        this.windowWidth = window.fullWidth; // 宽
      })();
    };
  }
}
</script>

<style>
</style>