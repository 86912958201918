<template>
  <div id="app">
    <HeaderVi></HeaderVi>
    <router-view></router-view>
    <FooterVi></FooterVi>
  </div>
</template>
<script>
import HeaderVi from '@/components/HeaderVi'
import FooterVi from './components/FooterVi/index.vue'
export default {
  name: 'App',
  components: { HeaderVi, FooterVi }
}
</script>

<style lang="less" scoped>
</style>
