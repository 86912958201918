<template>
  <div>
    <about></about>
  </div>
</template>

<script>
import about from './about/index.vue'
export default {
  name: 'AboutUs',
  components: { about }
}
</script>

<style>
</style>