//对于axios进行二次封装
import axios from 'axios'

// baseURL1 = "http://" + window.location.host + "/api";
const requests= axios.create({
	baseURL:"/tzone",
	// timeout:1
})
// 请求前拦截器
requests.interceptors.request.use((config)=>{
  return config
	// header
})
// 响应后拦截器
requests.interceptors.response.use((res)=>{
	//成功的回调函数：服务器响应回来后，响应拦截器可以监测到可以做一些事情
	return res.data
},(error)=>{
	console.log("err1:"+error);
	// 响应失败的回调函数
	return Promise.reject(new Error('Faile')); 
})
export default requests