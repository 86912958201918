<template>
  <div>
    <div class="banner-img">
      <img src="./image/BANNER.png"
           alt="">
      <div class="sites">
        <ul>
          <li @click="goTjt()">淘剧淘</li>
          <li @click="goRMm()">节目购</li>
          <li @click="goJmg()">融盟M</li>
          <li @click="goVl()">中国V链</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'bannerView',
  data () {
    return {
      abc: []
    }
  },
  methods: {
    goTjt () {
      let a = 'http://www.taojutao.com/'
      window.open(a, '_blank')

    },
    goRMm () {
      let a = 'http://2022.jiemugo.com/'
      window.open(a, '_blank')
    },
    goJmg () {
      let a = 'http://www.jiemugo.com/'
      window.open(a, '_blank')
    },
    goVl () {
      let a = 'https://www.vlian.cn/'
      window.open(a, '_blank')
    },

  },
  mounted () {
    this.$bus.$on('wangzhan', (data) => {
      this.abc = data
    })
  }

}
</script>

<style scoped>
.banner-img {
  position: relative;
}
.banner-img img {
  max-width: 100%;
  overflow: hidden;
  height: auto;
  width: auto\9;
  width: 100%;
}
.sites {
  position: absolute;
  right: 16%;
  bottom: 12%;
}
.sites ul li {
  display: inline-block;
  background: #fb3554;
  box-shadow: 5px 5px 13px 0 rgb(205 31 31 / 30%);
  border-radius: 100px;
  font-size: 18px;
  color: #fff;
  margin: 0 10px;
  padding: 8px 30px;
  cursor: pointer;
  font-weight: 600;
}
@media screen and (max-width: 992px) {
  .sites ul li {
    padding: 5px 18px;
  }
}
@media screen and (max-width: 768px) {
  .sites {
    right: 12%;
    bottom: 10%;
    width: 150px;
  }
  .sites ul li {
    display: inline-block;
    background: #fb3554;
    box-shadow: 5px 5px 13px 0 rgb(205 31 31 / 30%);
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    padding: 0;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    margin: 2px;
  }
}
</style>