import Vue from "vue";
import VueRouter from "vue-router";
//使用插件
Vue.use(VueRouter)
// 引用路由组件
import Home from '@/views/home'
import AboutUs from '@/views/AboutUs'

export default new VueRouter({
   routes:[
		{
			path:"/home",
			component:Home
		},
		{
			path:"/AboutUs",
			component:AboutUs
		},
		//重定向
		{
			path:'/',
			redirect:'/home'
		},
	 ],
	 scrollBehavior(to, from,savedPosition) {
    if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
			return savedPosition
		} else {
			return {x: 0, y: 0}//savedPosition也是一个记录x轴和y轴位置的对象
		}

  }
})