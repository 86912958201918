<template>
  <div class="bg">
    <div class="container">
      <img src="./image/矩阵部分中间图.png"
           alt="">
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'copyright'
}
</script>

<style scoped>
.bg {
  background: url(./image/矩阵部分底.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.container {
  width: 1250px;
  position: relative;
}
.container img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1250px) {
  .container {
    width: 90%;
  }
}
</style>