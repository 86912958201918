<template>
  <div class="container">
    <div class="beneficial">
      <ul class="clearfix">
        <li>
          <div class="youli">
            <img src="./image/youli1.png"
                 alt="">
            <div class="title">海量视频</div>
          </div>
        </li>
        <li>
          <div class="youli">
            <img src="./image/youli2.png"
                 alt="">
            <div class="title">正品授权</div>
          </div>
        </li>
        <li>
          <div class="youli">
            <img src="./image/youli3.png"
                 alt="">
            <div class="title">导向安全</div>
          </div>
        </li>
        <li>
          <div class="youli">
            <img src="./image/youli4.png"
                 alt="">
            <div class="title">合作共赢</div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Sitadvantage'
}
</script>

<style scoped>
.beneficial {
  margin-top: 50px;
  width: 100%;
  background-color: #e8f5ff;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  border: 1px solid #e8e8e8;
}
.beneficial ul li:nth-child(4) {
  border-right: none;
}
.beneficial ul li {
  float: left;
  width: 24%;
  text-align: center;
  margin: 20px 0;
  border-right: 1px solid #000;
}

.title {
  color: #000;
}
@media screen and (min-width: 992px) {
  .beneficial {
    margin-top: 50px;
  }
  .container {
    width: 1250px;
    margin: 0 auto;
  }

  .beneficial ul li img {
    width: 60px;
    height: 60px;
  }
  .title {
    color: #000;
    font-size: 16px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1250px) {
  .beneficial {
    margin-top: 30px;
  }
  .container {
    width: 85%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  .beneficial {
    margin-top: 0;
    background-color: transparent;
    box-shadow: none;
    border: 0;
  }
  .container {
    width: 90%;
  }
  .beneficial ul li {
    width: 25%;
    margin: 15px 0;
  }
  .title {
    color: #000;
    font-size: 12px;
    margin-top: 5px;
  }
  .beneficial ul li img {
    width: 30px;
    height: 30px;
  }
}
</style>