var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-texa"},[_c('div',{staticClass:"footer-l"},[_c('div',{on:{"click":_vm.tz}},[_vm._v("关于我们 "),_c('span')]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',[_vm._v("联系我们 ")])]),_c('div',{staticClass:"footer-d"},[_vm._v("Copyright© 2022 中广天择传媒股份有限公司 版权所有")]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("淘剧淘 "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("节目购 "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("融盟M "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("中国V链 "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-d"},[_vm._v("信息网络传播视听节目许可证号:  1184200118  "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("湘ICP备13006449号-2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-r"},[_c('div',[_c('img',{attrs:{"src":require("./image/tjt.jpg")}}),_vm._v(" "),_c('br'),_c('p',[_vm._v("淘剧淘官方微信")])]),_c('div',[_c('img',{attrs:{"src":require("./image/jmg.jpg")}}),_vm._v(" "),_c('br'),_c('p',[_vm._v("节目购官方微信")])]),_c('div',[_c('img',{attrs:{"src":require("./image/rmm.jpg")}}),_c('p',[_vm._v("融盟M官方微信")])])])
}]

export { render, staticRenderFns }