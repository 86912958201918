<template>
  <div class="footer">
    <div class="footer-texa">
      <div class="footer-l">
        <div @click="tz">关于我们
          <span></span>
        </div>
        <div>淘剧淘
          <span></span>
        </div>
        <div>节目购
          <span></span>
        </div>
        <div>融盟M
          <span></span>
        </div>
        <div>中国V链
          <span></span>
        </div>
        <div>联系我们
        </div>
      </div>
      <div class="footer-d">Copyright&copy; 2022 中广天择传媒股份有限公司 版权所有</div>
      <div class="footer-d">信息网络传播视听节目许可证号:&nbsp;&nbsp;1184200118&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备13006449号-2</a></div>

      
      <div class="footer-r">
        <div>
          <img src="./image/tjt.jpg"> <br>
          <p>淘剧淘官方微信</p>
        </div>
        <div>
          <img src="./image/jmg.jpg"> <br>
          <p>节目购官方微信</p>
        </div>
        <div>
          <img src="./image/rmm.jpg">
          <p>融盟M官方微信</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterVi',
  methods: {
    tz () {
      this.$router.push({
        path: '/AboutUs'
      })
    }
  }
}
</script>

<style lang="css" scoped>
.footer-texa {
  max-width: 550px;
  padding: 50px 0 60px;
  letter-spacing: 1px;
}
.footer {
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-size: 13px;
}
.footer-l {
  display: flex;
  justify-content: center;
}
.footer-l div {
  display: flex;
  margin: 3px;
  cursor: pointer;
}
.footer-l div span {
  width: 2px;
  margin: 0 15px;
  margin-top: 4px;
  height: 70%;
  background-color: #fff;
}
.footer-d {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.footer-r {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.footer-r div {
  text-align: center;
  margin: 0 18px;
}
.footer-r img {
  width: 60px;
  height: 60px;
}
.footer-r p {
  margin-top: 8px;
  font-size: 12px;
}
@media screen and (max-width: 992px) {
  .footer-texa {
    max-width: 450px;
    padding: 20px 0;
  }
  .footer-l div span {
    margin: 4px 0 0 8px;
  }
  .footer-d {
    margin-top: 8px;
    letter-spacing: 0px;
  }
  .footer-r {
    margin-top: 8px;
  }
  .footer-r div {
    margin: 0 8px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    font-size: 12px;
  }
}
</style>


