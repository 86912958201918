import Vue from 'vue'
import App from './App.vue'
//引入字体样式
import '@/assets/font_icon/iconfont.css'
//提示(可要可不要)
Vue.config.productionTip = false
//引入路由
import router from '@/router'

new Vue({
  render: h => h(App),
  router,
  beforeCreate(){
    Vue.prototype.$bus=this
  },
}).$mount('#app')
