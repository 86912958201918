<template>
  <div>
    <div class=" top">
      <div class="container">
        <div>
          <router-link to="/home">
            <div class="logo"><img src="./image/logo.png"></div>
          </router-link>
          <ul class="item">
            <span class="topxian"></span>
            <li @click="goWz(l.herf)"
                class="a"
                v-for="l,index in list"
                :key="index">
              <span class="active">{{l.title}}</span>
            </li>
          </ul>
          <div class="login">
            <span class="iconfont user">&#xe716;</span>
            <i>登录/注册</i>
          </div>
        </div>
      </div>
    </div>
    <div class="consult">
      <div class="message">
        <span>业务咨询请联系158-7310-0037</span>
        <div>
          <div class="office-hours">
            <span>工作日:</span>
            <i>9:00-18:00</i>
          </div>
          <div class="office-hours">
            <span>周末:&nbsp;&nbsp;</span>
            <i>10:00-20:00</i>
          </div>
        </div>
        <div class="trangle"></div>
      </div>
      <span class="iconfont">&#xe637;</span>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'HeaderVi',
  data () {
    return {
      list: [{
        title: '淘剧淘',
        herf: 'http://www.taojutao.com/'
      },
      {
        title: '节目购',
        herf: 'http://www.jiemugo.com/'
      },
      {
        title: '融盟M',
        herf: 'http://2022.jiemugo.com/'
      },
      {
        title: '中国V链',
        herf: 'http://www.vlian.cn/'
      },],

    }
  },
  methods: {
    goWz (href) {
      let a = href
      window.open(a, '_blank')
    },
  },
  beforeMount () {
    this.$bus.$emit("wangzhan", this.list)
  },
  mounted () {
    var a = parseInt($(".item .a .active").css('width'))
    $('.topxian').css('width', a)
    $('.topxian').css('left', $('.active')[0].offsetLeft)
    $(".item .a .active").on("mouseenter", function () {
      $(".topxian")
        .stop()
        .animate(
          {
            left: $(this)[0].offsetLeft
          },
          200
        );
    });
    $(".item .a .active").on("mouseleave", function () {
      $(".topxian")
        .stop()
        .animate(
          {
            // $(this)[0].offsetLeft
            left: $('.active')[0].offsetLeft
          },
          200
        );
    });
    $(window).resize(function () {
      $('.topxian').css('left', $('.active')[0].offsetLeft)
    });

  }
}
</script>

<style scoped>

.consult:hover .message {
  display: block;
}
.consult {
  position: fixed;
  right: 20px;
  top: 60%;
  width: 60px;
  height: 60px;
  z-index: 99;
  background-color: #4dade7;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}
.consult .iconfont {
  font-size: 30px;
  color: #fff;
  border-radius: 100%;
  line-height: 62px;
}
.message {
  position: absolute;
  top: -8px;
  right: 80px;
  width: 200px;
  height: 148px;
  background-color: #4dade7;
  border-radius: 6px;
  padding: 0 25px;
  display: none;
}
.message > span {
  display: block;
  width: 150px;
  height: 60px;
  background-color: #fff;
  color: #4dade7;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 6px;
}
.trangle {
  position: absolute;
  right: -20px;
  top: 20%;
  width: 0;
  height: 0;
  border: 10px solid #000;
  border-top-color: transparent;
  border-left-color: #4dade7;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.office-hours {
  width: 100%;
  color: #fff;
  text-align: left;
}
.office-hours span {
  margin-right: 25px;
}

.active {
  display: inline-block;
  width: 90px;
  height: 100%;
  font-weight: 600;
}

.top {
  height: 85px;
  line-height: 85px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.logo {
  float: left;
  width: 10%;
}
.logo img {
  height: auto;
}
.item {
  float: left;
  width: 80%;
  margin-left: 7%;
  position: relative;
}
.item li {
  float: left;
  text-align: center;
  color: #fff;
  cursor: pointer;
  width: 20%;
  font-size: 18px;
}
.topxian {
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
}
.login {
  width: 13%;
  float: right;
  text-align: center;
  position: relative;
  color: #fff;
  cursor: pointer;
  display: none;
}
.login i {
  padding-left: 35px;
}
.user {
  position: absolute;
  font-size: 30px !important;
}

@media screen and (min-width: 992px) {
  .logo {
    width: 10%;
  }
  .item {
    margin-left: 7%;
  }

  .item li {
    width: 20%;
    font-size: 18px;
  }
  .login i {
    padding-left: 35px;
  }
}
@media screen and (max-width: 992px) {
  .topxian {
    display: none;
  }
  .logo {
    width: 88px;
  }
  .logo img {
    width: 100%;
  }
  .item {
    margin-left: 5%;
  }
  .item li {
    width: 20%;
    font-size: 16px;
  }
  .login {
    width: 15%;
  }
  .login i {
    padding-left: 30px;
    font-size: 16px;
  }
  .user {
    position: absolute;
    font-size: 26px !important;
  }
}
@media screen and (max-width: 768px) {
  .top {
    height: 60px;
    line-height: 60px;

    width: 100%;
    z-index: 2;
  }
  .logo {
    width: 20%;
  }
  .item {
    display: none;
  }
  .login i {
    display: none;
    padding-left: 35px;
  }
}
</style>