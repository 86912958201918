<template>
  <div class="bg">
    <!-- <img src="./image/天择版权购-背景图.jpg"> -->
    <div class="aboutUs">
      <div class="title">
        <h3>平台介绍</h3>
        <i></i>
      </div>
      <div style="margin-top: 35px;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;天择版权购（网站域名：tvzonept.com）是中广天择传媒股份有限公司搭建运营的视频版权交易平台。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平台主要从事电视剧、节目、视频素材等视频版权的交易，汇聚了全国600+家电视台、300+家版权机构、10000+优质视频创作者，通过自营、代理发行的方式，提供安全、便捷、可靠的优质视频版权交易服务。并为广电媒体、融媒体机构打造行业资讯共享、运营模式交流、经营项目推广的聚集地。</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司地址：湖南省长沙市开福区月湖街道马栏山文创园中广天择</p>
        <p style="text-align: center;">联系方式：0731-88745259</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
}
</script>

<style  scoped>
.bg {
  width: 100%;
  height: 100vh;
  background: url(./image/天择版权购-背景图.jpg);
  background-size: cover;
}

.aboutUs {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  color: #fff;
}
.aboutUs p {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
}
.title h3 {
  font-size: 30px;
  text-align: center;
  letter-spacing: 3px;
}
.title {
  position: relative;
  margin-bottom: 25px;
}
.title i {
  margin-top: 28px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 15%;
  height: 2px;
  background: #fff;
  border-radius: 1px;
  text-align: center;
}
/* .active {
  background-color: #fff;
  border-right: none !important;
}
.tab_active {
  font-weight: 600;
  color: #202020;
  border-bottom: 3px solid #333;
}
.container {
  width: 1250px;
}
.tab_box {
  margin-top: 85px;
  text-align: center;
  margin: 115px auto 50px;
}
.tab_box div {
  display: inline-block;
  margin: 0 20px;
  line-height: 40px;
}

.box {
  border: 1px solid #ccc;
  margin-bottom: 30px;
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid #dddddd;
  overflow: hidden;
  height: 95vh;
}
.box .left,
.box .right {
  float: left;
  height: 95vh;
}
.left {
  width: 20%;
}
.left div {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
  width: 100%;
  text-align: center;
  line-height: 50px;
}
.right {
  width: 80%;
  padding: 20px 300px 20px 30px;
  background-color: #fff;
} */
@media screen and (max-width: 768px) {
  .bg {
    font-size: 12px;
  }
  .title h3 {
    font-size: 18px;
  }
  .aboutUs {
    width: 80%;
  }
}
</style>